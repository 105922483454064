import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { createBrowserHistory } from 'history'
import { FOOTER_ACTIONS, footerReducer } from './FooterReducers'
import { SIDEBAR_ACTIONS, sidebarReducer } from './SidebarReducers'
import {
  getConfigurationDefinition,
  getOsAndSupportedStatus,
  getStoreData as getLocalStoreData,
  resetLocalStorage,
  setStoreData as setLocalStoreData
} from '../utils/Utils'
import {
  ACCOUNT_TYPE_PERSONAL,
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  FeatureFlagKeys,
  LocalStoreKeys,
  Paths,
  UserOs
} from './Constants'
import {
  CmsDataFetchStatus,
  useContentStack
} from '@jarvis/olex-content-management'
import { FeatureFlagsContext } from './FeatureFlagsContext'
import { useHpPlusClient } from '../hooks/useHpPlusClient'
import { ConfigContextBusiness } from './ConfigContextBusiness'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EMPTY_FUNCTION = () => {}
const INIT = (i) => {
  return { ...i }
}
export const ConfigContext = React.createContext({
  contentStackCredential: null,
  publishCdmEvent: EMPTY_FUNCTION,
  setSelectedTab: EMPTY_FUNCTION,
  setPreviousSelectedTab: EMPTY_FUNCTION,
  setSelectedTabItem: EMPTY_FUNCTION,
  v1: {
    header: {
      setHeaderModelName: EMPTY_FUNCTION,
      setSelectedTab: EMPTY_FUNCTION,
      setPreviousSelectedTab: EMPTY_FUNCTION,
      selectedTab: 0,
      previousSelectedTab: 0,
      selectedTabItem: {}
    },
    sidebar: {
      setSidebarVisibility: EMPTY_FUNCTION,
      setCustomSidebar: EMPTY_FUNCTION
    },
    footer: {
      setFooterNavVisibility: EMPTY_FUNCTION,
      setCustomNavigation: EMPTY_FUNCTION,
      updateCurrentRoute: EMPTY_FUNCTION,
      updatePreviousRoute: EMPTY_FUNCTION,
      nextRoute: EMPTY_FUNCTION,
      setNextDisabled: EMPTY_FUNCTION,
      setNextVisible: EMPTY_FUNCTION,
      setBackDisabled: EMPTY_FUNCTION,
      setBackVisible: EMPTY_FUNCTION
    }
  },
  stack: null,
  sku: null,
  setSku: EMPTY_FUNCTION,
  printer: null,
  setPrinter: EMPTY_FUNCTION,
  paas: null,
  setPaas: EMPTY_FUNCTION,
  flags: {},
  pageDataCache: new Map(),
  tenantType: null,
  setTenantType: EMPTY_FUNCTION,
  os: null,
  isOsSupported: null,
  showInstallButton: null,
  isBrowserSupported: null,
  isMobile: null,
  localization: null,
  currentPath: null,
  isHpPlus: null,
  isFlowers: null,
  error: null,
  setError: EMPTY_FUNCTION,
  isInstallRedirectURL: null
})

const DEFAULT_COUNTRY = 'us'
const DEFAULT_LANGUAGE = 'en'

const ConfigProvider = (props) => {
  const {
    localization = {
      country: DEFAULT_COUNTRY,
      language: DEFAULT_LANGUAGE,
      enabled: false
    },
    analytics,
    getStoreData,
    setStoreData,
    stack,
    contentStackCredential,
    userAgent
  } = props

  /*
  declare functions to get and set store data here
  the functions from props will be used if they are passed in
  otherwise, the functions from utils/Utils.js will be used
   */
  const getStoreDataFunction = getStoreData || getLocalStoreData
  const setStoreDataFunction = setStoreData || setLocalStoreData

  const country = localization.country.toUpperCase()
  const language = localization.language
  const enabled = localization.enabled
  const history = createBrowserHistory({
    basename: enabled ? `/${country.toLowerCase()}/${language}` : `/`
  })
  const navigation = useMemo(
    () => props.navigation || history,
    [props.navigation, history]
  )
  const [lastKnownRoute, setLastKnownRoute] = useState()
  const [headerModelName, setHeaderModelName] = useState('')
  const [paas, setPaas] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [previousSelectedTab, setPreviousSelectedTab] = useState(0)
  const [sidebarState, sidebarDispatch] = useReducer(
    sidebarReducer,
    { sidebarVisible: false, customSidebar: {} },
    INIT
  )
  const [sku, setSku] = useState(null)
  const [printer, setPrinter] = useState(null)
  const [os, setOs] = useState(null)
  const [isMobile, setIsMobile] = useState(null)
  const [isBrowserSupported, setIsBrowserSupported] = useState(null)
  const [isOsSupported, setIsOsSupported] = useState(null)
  const [showInstallButton, setShowInstallButton] = useState(false)
  const [currentCustomNavigation, setCurrentCustomNavigation] = useState()
  const [tenantType, setTenantType] = useState(ACCOUNT_TYPE_PERSONAL)
  const [pageDataCache, setPageDataCache] = useState(new Map())
  const [selectedTabItem, _setSelectedTabItem] = useState({ 0: 0, 1: 0, 2: 0 })
  const [currentPath, setCurrentPath] = useState(navigation.location.pathname)
  const { isHpPlus } = useHpPlusClient({ printer })
  const { flags } = useContext(FeatureFlagsContext)
  const [compatibilityDefinition, setCompatibilityDefinition] = useState(null)
  const isFlowers = useMemo(
    () =>
      printer?.derivativeName?.toUpperCase().startsWith('CHERRY') ||
      printer?.derivativeName?.toUpperCase().startsWith('LOTUS'),
    [printer?.derivativeName]
  )
  const [error, setError] = useState(null)

  const osWithCompatibilityMessage = [UserOs.mac]

  const setSelectedTabItem = useCallback(
    (tab, subtab) => {
      _setSelectedTabItem((prevTabItem) => {
        return {
          ...prevTabItem,
          [tab]: subtab
        }
      })
    },
    [selectedTab]
  )

  const isInstallRedirectURL = useMemo(
    () =>
      [Paths.install, Paths.install_unsupported_os].includes(
        navigation?.location?.pathname
      ),
    [navigation?.location?.pathname]
  )

  useEffect(() => {
    if (!sku || isInstallRedirectURL) {
      return
    }
    const storedUserAgent = getLocalStoreData(LocalStoreKeys.lastUserAgent)
    if (!storedUserAgent) {
      setLocalStoreData(LocalStoreKeys.lastUserAgent, userAgent)
    } else if (userAgent !== storedUserAgent) {
      navigation.push(`/${sku}`)
      setLocalStoreData(LocalStoreKeys.lastUserAgent, userAgent)
    }
  }, [sku, userAgent])

  const { pageData: configurationsPageData } = useContentStack({
    content_type: ContentStackTypes.configuration_definitions
  })

  const { pageData: externalLinksPageData } = useContentStack({
    content_type: ContentStackTypes.external_links
  })

  const { pageData: tdeLayoutPageData } = useContentStack({
    content_type: ContentStackTypes.tde_layout
  })

  useEffect(() => {
    if (
      !pageDataCache.get(ContentStackTypes.external_links.content_type_uid) &&
      !pageDataCache.get(ContentStackTypes.tde_layout.content_type_uid) &&
      !pageDataCache.get(
        ContentStackTypes.configuration_definitions.content_type_uid
      ) &&
      externalLinksPageData?.status === CmsDataFetchStatus.LOADED &&
      tdeLayoutPageData?.status === CmsDataFetchStatus.LOADED &&
      configurationsPageData?.status === CmsDataFetchStatus.LOADED
    ) {
      pageDataCache.set(
        ContentStackTypes.external_links.content_type_uid,
        externalLinksPageData.data
      )
      pageDataCache.set(
        ContentStackTypes.tde_layout.content_type_uid,
        tdeLayoutPageData.data
      )
      pageDataCache.set(
        ContentStackTypes.configuration_definitions.content_type_uid,
        configurationsPageData.data
      )
      setPageDataCache(new Map(pageDataCache))
    }
  }, [
    tdeLayoutPageData,
    externalLinksPageData,
    configurationsPageData,
    pageDataCache
  ])

  useEffect(() => {
    const configurationsPageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )
    if (!userAgent || !configurationsPageData) {
      return
    }
    const compatibilityDefinitionJSON = getConfigurationDefinition(
      configurationsPageData.definitions,
      ConfigurationDefinitionsKeys.compatibilityDefinitions
    )

    const {
      isMobile,
      os,
      isOsSupported,
      showInstallButton,
      isBrowserSupported
    } = getOsAndSupportedStatus(userAgent, compatibilityDefinitionJSON)

    setOs(os)
    setIsBrowserSupported(isBrowserSupported)
    setIsOsSupported(isOsSupported)
    setShowInstallButton(showInstallButton)
    setIsMobile(isMobile)
    setCompatibilityDefinition(compatibilityDefinitionJSON)
  }, [userAgent, pageDataCache])

  const [footerState, footerDispatch] = useReducer(
    footerReducer,
    {
      footerNavigationVisible: false,
      customNavigation: new Map(),
      currentRoute: {},
      nextDisabled: false,
      nextVisible: true,
      nextButtonLabel: null,
      backDisabled: false,
      backVisible: true,
      backButtonLabel: null
    },
    INIT
  )

  const refreshCurrentPath = useCallback(() => {
    setCurrentPath(navigation.location.pathname)
  }, [navigation.location.pathname])

  useEffect(() => {
    window.addEventListener('popstate', refreshCurrentPath)

    return () => {
      window.removeEventListener('popstate', refreshCurrentPath)
    }
  }, [refreshCurrentPath])

  useEffect(() => {
    if (
      footerState.customNavigation &&
      currentPath &&
      currentPath !== footerState.currentRoute.path
    ) {
      const allKeys = Array.from(footerState.customNavigation.keys())
      const currentKey = allKeys.find(
        (key) => footerState.customNavigation.get(key).path === currentPath
      )
      if (currentKey) {
        setLastKnownRoute(currentKey)
        updateCurrentRoute({ key: currentKey })
      }
    }
  }, [footerState.customNavigation, currentPath])

  //watch for sku changes and save to local storage
  useEffect(() => {
    if (sku) setStoreDataFunction(LocalStoreKeys.printer_sku, sku)
  }, [sku])

  //watch for sku changes and save to local storage
  useEffect(() => {
    if (localization) {
      const existingLocale = getStoreDataFunction(LocalStoreKeys.localization)
      const savedSku = getStoreDataFunction(LocalStoreKeys.printer_sku)
      if (
        existingLocale &&
        existingLocale !== localization?.locale.toLowerCase()
      ) {
        if (navigation.location.pathname === `/${savedSku}`) {
          resetLocalStorage(localization.locale)
          navigation.push(`${savedSku}${navigation.location.search}`)
        }
      } else {
        setStoreDataFunction(
          LocalStoreKeys.localization,
          localization?.locale.toLowerCase()
        )
      }
    }
  }, [localization])

  //watch for printer changes and save to local storage
  useEffect(() => {
    if (printer) {
      setStoreDataFunction(LocalStoreKeys.printer, printer)
      setHeaderModelName(printer?.productShortName)
    }
  }, [printer])

  /************************
   * SIDEBAR
   ************************/
  const setSidebarVisibility = (visible) => {
    sidebarDispatch({ type: SIDEBAR_ACTIONS.VISIBILITY, visible })
  }

  const setCustomSidebar = (sidebar) => {
    sidebarDispatch({ type: SIDEBAR_ACTIONS.CUSTOM_SIDEBAR, sidebar })
  }

  /************************
   * FOOTER
   ************************/
  useEffect(() => {
    if (
      lastKnownRoute &&
      footerState?.currentRoute &&
      footerState.currentRoute.key !== lastKnownRoute
    ) {
      setLastKnownRoute(footerState.currentRoute.key)
      navigation.push(footerState.currentRoute.path)
    } else if (!lastKnownRoute && footerState.currentRoute) {
      setLastKnownRoute(footerState.currentRoute.key)
    }
  }, [footerState, navigation, lastKnownRoute])

  const setFooterNavVisibility = (visible) => {
    footerDispatch({ type: FOOTER_ACTIONS.VISIBILITY, visible })
  }

  const setCustomNavigation = (navigation) => {
    footerDispatch({
      type: FOOTER_ACTIONS.NAVIGATION,
      navigation
    })
  }

  const nextRoute = (options) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT, options })
  }

  const updateCurrentRoute = (route) => {
    footerDispatch({ type: FOOTER_ACTIONS.UPDATE, route })
  }

  const updatePreviousRoute = (options) => {
    footerDispatch({ type: FOOTER_ACTIONS.PREVIOUS, options })
  }

  const setNextVisible = (visible) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT_VISIBLE, visible })
  }

  const setNextDisabled = (disabled) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT_DISABLED, disabled })
  }

  const setNextButtonLabel = (label) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT_LABEL, label })
  }

  const setBackVisible = (visible) => {
    footerDispatch({ type: FOOTER_ACTIONS.BACK_VISIBLE, visible })
  }

  const setBackDisabled = (disabled) => {
    footerDispatch({ type: FOOTER_ACTIONS.BACK_DISABLED, disabled })
  }

  const setBackButtonLabel = (label) => {
    footerDispatch({ type: FOOTER_ACTIONS.BACK_LABEL, label })
  }

  const header = {
    setHeaderModelName,
    selectedTab,
    setSelectedTab,
    previousSelectedTab,
    setPreviousSelectedTab,
    selectedTabItem,
    setSelectedTabItem
  }
  const sidebar = {
    setSidebarVisibility,
    setCustomSidebar
  }
  const footer = {
    setFooterNavVisibility,
    currentCustomNavigation,
    setCustomNavigation,
    updateCurrentRoute,
    updatePreviousRoute,
    nextRoute,
    setNextVisible,
    setNextDisabled,
    setNextButtonLabel,
    setBackVisible,
    setBackDisabled,
    setBackButtonLabel
  }

  let publishCdmEvent = EMPTY_FUNCTION
  if (analytics?.publishCdmEvents) {
    publishCdmEvent = (events, metadata) => {
      events = Array.isArray(events) ? events : [events]
      metadata
        ? analytics.publishCdmEvents(events, metadata)
        : analytics.publishCdmEvents(events)
    }
  }

  const paasRules = useCallback(
    (customNavigation) => {
      if (paas) {
        if (customNavigation.has('printer-use')) {
          customNavigation.get('alignment').next = 'printer-use'
          customNavigation.get('printer-use').previous = 'alignment'
        } else if (
          customNavigation.has('alignment') &&
          customNavigation.has('hp-software')
        ) {
          customNavigation.get('alignment').next = 'hp-software'
          customNavigation.get('hp-software').previous = 'alignment'
        }
      } else if (
        customNavigation.has('alignment') &&
        customNavigation.has('hp-software')
      ) {
        customNavigation.get('alignment').next = 'hp-software'
        customNavigation.get('hp-software').previous = 'alignment'
      }

      if (
        !customNavigation.has('printer-use') &&
        !customNavigation.has('setup-checklist') &&
        customNavigation.has('alignment') &&
        customNavigation.has('unsupported-os') &&
        !osWithCompatibilityMessage.includes(os) &&
        (!isBrowserSupported || !isOsSupported)
      ) {
        customNavigation.get('alignment').next = 'unsupported-os'
        customNavigation.get('unsupported-os').previous = 'alignment'
      }
    },
    [isBrowserSupported, isOsSupported, paas]
  )

  const usbPath = useCallback(
    (customNavigation) => {
      if (os === UserOs.mac) {
        customNavigation.get('select-usb-on-display').next = 'start-airprint'
        customNavigation.get('start-airprint').previous =
          'select-usb-on-display'
      }
    },
    [os]
  )

  const updateLandingPageWithSKU = useCallback(
    (customNavigation) => {
      if (sku) {
        const printerSpecific = customNavigation.get(
          'printer-specific-landing-page'
        )
        printerSpecific.path = `/${sku}`
      }
    },
    [sku]
  )

  const usbPaths = [
    Paths.select_usb_on_display,
    Paths.connect_usb,
    Paths.driver_download,
    Paths.driver_after
  ]

  useEffect(() => {
    ConfigContextBusiness.redirectsToNotFoundWhenTheSkuIsNotCompatibleWithUSB({
      currentPath,
      navigation,
      pageDataCache,
      sku,
      usbPaths,
      setError
    })
  }, [pageDataCache, navigation, currentPath, sku])

  useEffect(() => {
    if (flags?.[FeatureFlagKeys.navigation]) {
      const CUSTOM_NAV = flags[FeatureFlagKeys.navigation]
      const customNavigation = new Map(
        Object.entries(CUSTOM_NAV).sort(([, value1]) => {
          return value1.previous ? 1 : -1
        })
      )

      paasRules(customNavigation)
      usbPath(customNavigation)
      updateLandingPageWithSKU(customNavigation)
      setCurrentCustomNavigation(customNavigation)
    }
  }, [flags, paas, paasRules, updateLandingPageWithSKU])

  useEffect(() => {
    if (currentCustomNavigation) {
      footer.setCustomNavigation(currentCustomNavigation)
    }
  }, [currentCustomNavigation])

  useEffect(() => {
    if (
      currentCustomNavigation &&
      !currentCustomNavigation.has('printer-use') &&
      !tenantType
    ) {
      setTenantType(ACCOUNT_TYPE_PERSONAL)
    }
  }, [currentCustomNavigation, tenantType])

  const configState = useMemo(() => {
    return {
      contentStackCredential,
      navigation,
      states: {
        header: {
          headerModelName,
          selectedTab,
          previousSelectedTab,
          selectedTabItem,
          setSelectedTabItem
        },
        sidebar: sidebarState,
        footer: footerState
      },
      header,
      sidebar,
      footer,
      publishCdmEvent,
      setSelectedTab,
      setPreviousSelectedTab,
      stack,
      sku,
      setSku,
      printer,
      setPrinter,
      paas,
      setPaas,
      setStoreData: setStoreDataFunction,
      getStoreData: getStoreDataFunction,
      flags,
      pageDataCache,
      tenantType,
      setTenantType,
      os,
      isBrowserSupported,
      isOsSupported,
      showInstallButton,
      isMobile,
      localization,
      currentPath,
      isHpPlus,
      compatibilityDefinition,
      isFlowers,
      error,
      setError,
      isInstallRedirectURL
    }
  }, [
    contentStackCredential,
    navigation,
    headerModelName,
    selectedTab,
    previousSelectedTab,
    selectedTabItem,
    sidebarState,
    footerState,
    header,
    sidebar,
    footer,
    publishCdmEvent,
    setSelectedTab,
    setPreviousSelectedTab,
    setSelectedTabItem,
    sku,
    setSku,
    printer,
    setPrinter,
    paas,
    setPaas,
    setStoreDataFunction,
    getStoreDataFunction,
    flags,
    pageDataCache,
    tenantType,
    setTenantType,
    os,
    isBrowserSupported,
    isOsSupported,
    showInstallButton,
    isMobile,
    currentPath,
    localization,
    isHpPlus,
    compatibilityDefinition,
    isFlowers,
    error,
    setError,
    isInstallRedirectURL
  ])

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
