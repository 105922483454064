export const VENEER_SECONDARY_BUTTON_APPEARANCE = 'secondary'
export const VENEER_PRIMARY_BUTTON_APPEARANCE = 'primary'
export const ACCOUNT_TYPE_COMPANY = 'BusinessTransactionalSMB'
export const ACCOUNT_TYPE_PERSONAL = 'Personal'

export const ContentStackTypes = {
  tde_layout: {
    content_type_uid: 'tde_layout',
    entry_uid: 'bltbee092510ffe4cac'
  },
  tde_header: {
    content_type_uid: 'tab_definition'
  },
  external_links: {
    entry_uid: 'bltc022dbc8cc109a41',
    content_type_uid: 'external_links'
  },
  configuration_definitions: {
    content_type_uid: 'configuration_definitions'
  }
}

export const GlobalFieldTypes = {
  derivative_names: 'derivative_names'
}

export const LocalStoreKeys = {
  localization: 'localization',
  user: 'user',
  printer_sku: 'printerSKU',
  printer: 'printer',
  lastUserAgent: 'lastUserAgent'
}

export const ContentTypePathDefinition = {
  hp_software: {
    defaultRedirect: '/hp-software'
  },
  page_not_found: {
    defaultRedirect: '/not-found'
  },
  printer_use_choice: {
    defaultRedirect: '/printer-use'
  },
  connect_printer_to_network: {
    defaultRedirect: '/printer-network'
  },
  setup_checklist: {
    defaultRedirect: '/setup-checklist'
  },
  tde_layout: {
    defaultRedirect: '/country-language'
  },
  instruction_carousel: {
    defaultRedirect: '/country-language',
    types: {
      'country-language': '/country-language',
      'load-paper': '/load-paper',
      'install-ink': '/install-ink',
      alignment: '/alignment'
    }
  },
  generic_card: {
    defaultRedirect: '/connect-usb',
    types: {
      CONNECT_USB: '/connect-usb',
      DRIVER_NOT_FOUND: '/driver-after',
      FINISH_SETUP: '/driver-after',
      INSTALL_DRIVER: '/driver-download',
      INSTALL_DRIVER_INTRANET: '/driver-download',
      SELECT_USB_DISPLAY: '/usb-printer-display',
      SKIP_INTERNET_CONNECTION: '/skip-internet-connection',
      START_AIRPRINT: '/start-airprint'
    }
  },
  modal: {
    defaultRedirect: '/setup-checklist',
    types: {
      TROUBLE_INSTALLING: '/hp-software',
      WIFI_SETUP: '/setup-checklist',
      USE_OFFLINE: '/hp-software'
    }
  }
}

export const Paths = {
  home: '/:sku',
  country_language: '/country-language',
  load_paper: '/load-paper',
  install_ink: '/install-ink',
  alignment: '/alignment',
  connect_printer_to_network: '/printer-network',
  set_up_checklist: '/setup-checklist',
  printer_use_choice: '/printer-use',
  setup_checklist: '/setup-checklist',
  connect_usb: '/connect-usb',
  unsupported_os: '/unsupported-os',
  driver_download: '/driver-download',
  driver_after: '/driver-after',
  select_usb_on_display: '/usb-printer-display',
  not_found: '/not-found',
  hp_software: '/hp-software',
  finish_setup_business: '/finish-setup-business',
  skip_internet_connection: '/skip-internet-connection',
  start_airprint: '/start-airprint',
  start_printing_mobile: '/start-printing-mobile',
  install: '/install',
  install_unsupported_os: '/install-unsupported-os'
}

export const FeatureFlagKeys = {
  navigation: 'olex-release-traffic-director-navigation',
  betaAccessCode: 'olex-release-traffic-director-beta-access-code',
  saltString: 'olex-traffic-director-salt',
  landingPagePoC: 'olex-release-traffic-director-landing-page-poc'
}

export const UserOs = {
  ios: 'ios',
  android: 'android',
  linux: 'linux',
  mac: 'mac',
  windows: 'windows',
  chrome: 'chrome',
  unknown: 'unknown'
}

/**
 * Constant used to validate the compatible versions of the OS.
 * The minVersion is the minimum version of the OS that is supported.
 * The showInstallButton is a boolean that indicates if the install button should be shown.
 */
export const UserOsSupportedVersion = {
  ios: {
    minVersion: 0,
    showInstallButton: true
  },
  android: {
    minVersion: 0,
    showInstallButton: true
  },
  linux: {
    minVersion: -1,
    showInstallButton: false
  },
  mac: {
    minVersion: 11,
    showInstallButton: true
  },
  windows: {
    minVersion: 10,
    showInstallButton: false
  },
  chrome: {
    minVersion: -1,
    showInstallButton: false
  },
  unknown: {
    minVersion: -1,
    showInstallButton: false
  }
}

export const UserOsRegExs = {
  macos_platforms: /(MACINTOSH)|(MACINTEL)|(MACPPC)|(MAC68K)/i,
  ios_platforms: /(IPHONE)|(IPAD)|(IPOD)|(CRIOS)/i,
  android: /ANDROID/i,
  linux: /LINUX/i,
  win: /WIN/i,
  chrome: /(CROS)/i
}

export const ConfigurationDefinitionsKeys = {
  compatibilityDefinitions: 'compatibility',
  friendlyUrl: 'friendly_url',
  skuWithoutUsbDefinition: 'sku_without_usb'
}

export const Errors = {
  pls_empty: 'pls_empty',
  pls_error: 'pls_error',
  cms_header_error: 'cms_header_error',
  sku_incompatible_with_usb: 'sku_incompatible_with_usb'
}
